import axios from 'axios';
import { useRef, useState } from 'react';
import logo from './png-clipart-whatsapp-viber-telegram-instant-messaging-mobile-app-whatsapp-purple-text-removebg-preview.png'

import './App.css';

function App() {
  const [registr, setRegistr] = useState(false);
  const [registreded, setSegistred] = useState(false);
  const [stadartPluse, setSstadartPluse] = useState(false);

  const data = {};

  const openRegistr = ()=>{
    if(registr) {
      setRegistr(false)
    } else {
      setRegistr(true);
    }
  }

  const openRegistrPluse = ()=>{
    setSstadartPluse(true)
    if(registr) {
      setRegistr(false)
    } else {
      setRegistr(true);
    }
  }

  const registred = async (e, a) =>{
    setSegistred(true);
    setSstadartPluse(false)
    await axios.post(`https://mailbakend-e8vu.onrender.com/sendMail`, { data, stadartPluse })
    setTimeout(()=>{
      setSegistred(false);
        setRegistr(false)
      }, 9000)
  }
  const handelName =(e)=>{
     data.name = e.target.value
  }
  const handelEmail =(e)=>{
    data.email = e.target.value
  }
  const handelSurname =(e)=>{
    data.surname = e.target.value
  }
  const handelPhone =(e)=>{
    data.phone = e.target.value
  }
  return (
    <div className="App">
      <div>
      {!registr ? <div style={{ margin: '30px', marginBottom: '50px', fontWeight: 'bold' }}>Սիրելիներս դիմավորեք նոր Աստղային Beauty Talks-ը<br/>
      <img src='https://firebasestorage.googleapis.com/v0/b/telegrambot-d128a.appspot.com/o/IMG_8763.JPG?alt=media&token=3f528ba9-5b13-4b1d-a6c3-69821421e7f7' alt='aa' width='100%' height='100%'/>

      <div  style={{ margin: '30px', marginBottom: '50px', fontWeight: 'bold' }}></div>
      
      🌟 Ստանդարտ փաթեթ. 
Իդեալական սկիզբ դիմահարդարման աշխարհում։ 
Ներառված է մեկ վիդեո դաս, որտեղ ես կկիսվեմ Երեկոյան իդեալական դիմահարդարուշ ստեղծելու իմ փորձով և տեխնիկայով և նյութաճանաչություն։ <br/><br/>
<img src='https://firebasestorage.googleapis.com/v0/b/telegrambot-d128a.appspot.com/o/photo_2024-02-01_18-31-28.jpg?alt=media&token=34506205-35eb-41a8-ab8f-f8295b32004d' alt='aa' width='100%' height='100%'/>
<br/>
🌟 Պրեմիում փաթեթ 
Սուզվեք ավելի խորը: 
Ներառված է՝ երեկոյան դիմահարդարում,  մեկ ուղիղ միացում, որտեղ պատասխանելու եմ ձեր հարցերին, նյութաճանաչություն , ինչպես նաև բոնուսային դաս նկարելու վերաբերյալ , որտեղ կսովորեք թե ինչպես ստանալ ձեր դիմահարդարման գեղեցիկ լուսանկարները:
 <br/>
 <br/>
 <img src='https://firebasestorage.googleapis.com/v0/b/telegrambot-d128a.appspot.com/o/photo_2024-02-01_18-31-27.jpg?alt=media&token=0788fb48-5fb8-46d2-b39f-687d5ac3ec48' alt='aa' width='100%' height='100%'/>

 🌟 VIP փաթեթ
Բացահայտե՛ք աստղերի գաղտնիքները։
Ներառված է՝ Աստղային դիմահարդարում, երեկոյան դիմահարդարում,  նյութաճանաչություն, 2 ուղիղ միացում, որտեղ պատասխանելու եմ ձեր հարցերին և բոնուսային դաս նկարելու վերաբերյալ ։ <br/> <br/>
<img src='https://firebasestorage.googleapis.com/v0/b/telegrambot-d128a.appspot.com/o/photo_2024-02-01_19-11-28.jpg?alt=media&token=1b53bd4a-7a16-4f23-aba5-181e3d66a22e' alt='aa' width='100%' height='100%'/>

Բոլոր դասընթացների ավարտին կստանաք սրերտիֆիկատ ընտրած փաթեթի համապատասխան: <br/><br/>

Հատուկ առաջարկ մեկնարկից , առաջին 5 օրում գրանցվելողների համար  <br/><br/>
Գրանցվեք ցանկացած դասընթացի, անկախ փաթեթից, դասընթացի մեկնարկի առաջին հինգ օրվա ընթացքում և ստացեք  դաս «Ինքդ քես դիմահարդար և վարսահարդար🧚🏼‍♀️» որպես նվեր։ Այս դասընթացում ես կբացահայտեմ ձեր մազերը արագ և գեղեցիկ հարդարելու և տպավորիչ դիմահարդարման հետաքրքրաշարժ նրբություններ, որոնք ձեզ կոգնեն արագ և գեղեցիկ տեսք ունենալ ցանկացած առիթի համար։
 Բաց մի թողեք՝ բաժանորդագրվեք նախօրոք և բարելավեք ձեր գեղեցկության հմտությունները:✨✨✨  <br/><br/>

 Միացե՛ք հիմա։
</div> : null}
<hr/>
 <div style={{ fontWeight: 'bold' }}>
Ստանդարտ փաթեթի արժեքը լինելու է 19500 դրամ (4500 RUB , 49$, 46 EURO)<br/>
Պրեմիում փաթեթի արժեքը լինելու Է 29500 դրամ (6700 RUB , 74$, 69 EURO) <br/>
VIP փաթեթի արժեքը լինելու Է 49500 դրամ (11300 RUB , 124$, 116 EURO) <br/>
</div> 

<div style={{ fontWeight: 'bold' }}>
<br/>
<br/>
Վճարման կարգը<br/>
Հայաստանից կարող եք կատարել վճարում ցանկացած տերմինալով,բանկով կամ օնլայն հավելվածներով<br/>
Ardshinbank քարտի տվյալներ 4454300004260383 <br/>
Gayane Hunanyan<br/>
Վճարում կատարելուց հետո կտրոնը պարտադիր նկարեք և ուղարկեք (+1(818)2137315  - whatsapp)<br/>

<br/>
<br/>
Способ оплаты из России <br/>
Сбербанк перевод на карту <br/>
Унанян Анаит Торосовна <br/>
Номер телефона 89057456828<br/>
После совершения платежа обязательно сфоткайте квитанцию и отправьте на (+1(818)2137315  - whatsapp)<br/>
<br/>
<br/>
Payment method USA <br/>
Zelle <br/>
Gayane Hunanyan <br/>
+1(818)2137315<br/>
Attention!!!<br/>
After making the payment,take a picture of the check and send it (+1(818)2137315  - whatsapp)<br/>
</div> 

      </div>
    </div>
  );
}

export default App;
